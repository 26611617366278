import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

const Comentarios = (props) => {
	const postId = props.id;

    const getData = graphql`{
	   allWordpressWpComments(sort: {order: ASC, fields: date}){
	      edges {
	        node {
	          id
	          wordpress_id
	          post 
		      author_name
		      author_url    
		      author_avatar_urls {
		        wordpress_48
		      }		          
	          date(formatString: "DD.MM.YYYY")
	          content
	          status
	        }
	      }
	    }
    } `

    const data = useStaticQuery(getData);
    const comments = data.allWordpressWpComments.edges.filter(
        comment =>
          comment.node.post === postId && comment.node.status === 'approved'
      );

    // console.log(comments);

	const getComments = () =>  comments.map((obj, index)  => {
		const comment = obj.node;
		return (
			<article key={index} className="comment-item">
				<div className="comment-head">
					<img className="avatar" src={comment.author_avatar_urls.wordpress_48} alt={comment.author_name} />
					<div className="comment-info">
						<h5 className="comment-author">{comment.author_name}</h5>
						<span className="comment-date">{comment.date}</span>
					</div>
				</div>
				<div className="comment-description" dangerouslySetInnerHTML={{__html: comment.content}} />	
			</article>
	    )
	}); 

	if (comments.length > 0){
		return (
			<section className="bloque-comentarios comentarios-list">     
				<h3 className="title">Comentarios ({ comments.length })</h3>
				{ getComments()	}
			</section>
		)		
	}
	else 
		return ''
}

export default Comentarios;