import React, { useState } from 'react'
import Recaptcha from 'react-recaptcha'
import { Helmet } from 'react-helmet'

export const ComentarioForm = (props) => {

    const [ formData, setFormData ] = useState({
        author_email: '',
        content: '',
        author_name: '',
        // date: '',
        post: props.id
    })

    const [ display, setDisplay ] = useState({
        timeout: true
    })

    const [ formError, setFormError ] = useState({ });
    const [ success, setSuccess ] = useState({
        state: false
    })
    const [ captchaState, setCaptcha ] = useState({ 
        captcha: false
    })


    const onChange = e => {
        setFormData({
            ...formData,
            // date: `${new Date().toISOString().slice(0, 10).split('-')[2]}/${new Date().toISOString().slice(0, 10).split('-')[1]}/${new Date().toISOString().slice(0, 10).split('-')[0]}`,
            [e.target.name]: e.target.value
        });
    }

    const recaptchaLoaded = () => console.log("Captcha successfully loaded") 
    const verifyCallback = (response) => {
        setCaptcha({
            captcha: !!response
        })
    }

    const handleComment = (e) => {
        e.preventDefault();

        setFormError({});
        let errors = {};
        let formOk = true;

        if(!/^[a-zA-Z]+[a-zA-Z0-9]*([.-][a-zA-Z]+[a-zA-Z0-9.]*)*@[a-zA-Z]+[a-zA-Z0-9]*\.[a-zA-Z]{2,}$/.test(formData.author_email)) {
            errors.author_email = true;
            formOk = false;
        }

        if(formData.author_name.length < 2) {
            errors.author_name = true;
            formOk = false;
        }

        if(!formData.content) {
            errors.content = true;
            formOk = false;
        }

        if(!captchaState.captcha) {
            errors.captcha = true;
            formOk = false;
        }

        setFormError(errors);

        if(formOk) {

            setSuccess({
                state: true
            })

            setDisplay({
                timeout: true
            })

            setTimeout( () => setDisplay({
                 timeout: false
             }), 15000);

            const data = JSON.stringify(formData)
            fetch(`https://blog.serviciosinformaticos.net/wp-json/wp/v2/comments`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: data,
                })
                .then((response) => {
                    if (response.ok === true) {
                        console.log('Submitted successfully!')
                    }

                    return response.json();
                })
                .then((object) => {
                    // Comment submission failed.
                    // Output `object.message` to see the error message.
                })
                .catch(error => console.error('Error:', error));


        }
    }


    return (

        <section className="bloque-comentarios comentarios-form">
            <Helmet>
                {/* Faig servir Helmet que ve per defecte amb el gatsby starter,
                ja que ens cal aquest script en html per renderitzar el captcha */}
                <script src="https://www.google.com/recaptcha/api.js" async defer></script>
            </Helmet>

            <div className="comentarios-form-header">
                <h3>Deja un comentario</h3>
                <p>Tu email no se mostrará publicamente. Todos los campos son obligatorios.</p>
            </div>

            <form onSubmit={handleComment} onChange={onChange}>

                <div className={`form-group ${formError.author_name ? 'error' : ''}`}>
                    <label htmlFor="input-name">Nombre</label>
                    <input
                        type="text"
                        className="form-control"
                        id="input-name"
                        name="author_name"
                    />
                    { formError.author_name && <span className="error-text"> El nombre debe tener un mínimo de 2 caracteres  </span> }

                </div>
                <div className={`form-group ${formError.author_email ? 'error' : ''}`}>
                    <label htmlFor="input-email">Email</label>
                    <input
                        type="text"
                        className="form-control"
                        id="input-email"
                        name="author_email"
                    />
                    { formError.author_email && <span className="error-text"> Introduce un email válido!  </span> }

                </div>

                <div className={`form-group ${formError.content ? 'error' : ''}`}>  
                    <label htmlFor="textarea-message">Mensaje</label>
                    <textarea 
                        className="form-control"
                        id="textarea-message"
                        rows="5"
                        name="content"
                    />
                        { formError.content && <span className="error-text"> El mensaje no puede estar vacío  </span> }
                </div>                

                <div className={`submit-group ${success.state && display.timeout ? 'success' : ''}`}>
                    <div className="btn-group">  
                        <button 
                            type="submit"
                            className={`btn btn-accent col-1`}>
                            Enviar comentario
                        </button>
                    </div>

                    {!success.state ? (
                        <Recaptcha
                            sitekey="6LfAbL4ZAAAAAKfQ9DJZ-YJOGgpE8LYdMZo3bGL4"
                            render="explicit"
                            onloadCallback={recaptchaLoaded}
                            verifyCallback={verifyCallback}
                        />) 
                        : display.timeout && <span className="success-text">Mensaje enviado correctamente. Esperando aprobación</span>}
                    { formError.captcha && <span className="error-text"> Verifica que no eres un robot </span>}
                </div>


            </form>
        </section>

    )
}

export default ComentarioForm

/*
sitekey netlify: 6LfAbL4ZAAAAAKfQ9DJZ-YJOGgpE8LYdMZo3bGL4
sitekey localhost: 6LcyIrkZAAAAAK1Z3MM7Cr_6jhvphpGhQRKMidw0
*/