import React, { useState } from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import Seo from '../components/seo'
import Sidebar from '../components/sidebar'
import ComentForm from '../components/blog-comentario-form'
import Comentarios from '../components/blog-comentarios'
import { FaWhatsapp, FaTwitter, FaLinkedinIn, FaFacebookF } from "react-icons/fa";

export default ({ pageContext }) => {
    const [childData, setChildData] = useState("");
    const slug = pageContext.categories[0].slug + "/" + pageContext.slug;
    const BASE_URL = "https://www.serviciosinformaticos.net/";
    const tw_link = "https://twitter.com/share?url=" + BASE_URL + slug;
    const fb_link = "https://www.facebook.com/sharer/sharer.php?u=" + BASE_URL + slug;
    const ln_link = "https://www.linkedin.com/shareArticle?mini=true&url=" + BASE_URL + slug;
    const wa_link = "https://wa.me/?text=" + BASE_URL + slug;

    return childData ? (
        <Layout className="page-blog page-blog-list">
            <Seo title="Resultados de búsqueda - Blog"/>
            <section className="blog-content container">
                <div className="blog-content-main">
                    {childData}
                </div>

                <Sidebar passChildData={setChildData}/>
            </section>
        </Layout>
    ) : (
        <Layout className="page-blog page-blog-post">
            <Seo 
                title={pageContext.title}
                description="Blog de DMS sobre IBM Informix, Workforce Productivity, SP-Expert Sistemas, ITIL, Lean Startup y Cloud"
                />            
            <section className="container blog-content">
                <div className="blog-content-main">
                        <aside className="post-info">
                            <span className="post-date">{pageContext.date}</span>
                            <span className="post-category">
                                <Link to={`/blog/${pageContext.categories[0].slug}`}>
                                    {pageContext.categories[0].name}
                                </Link>
                            </span>                        
                            <span className="post-autor">{pageContext.author.name}</span>
                        </aside>   

                    <h1 className="post-title">{pageContext.title}</h1>


                    <div className="post-content" dangerouslySetInnerHTML={{__html: pageContext.content}} />


                    <div className="post-info-container">
                        <ul className="social-share">
                            <li><a href={tw_link} target="_blank" rel="nofollow noreferrer"><FaTwitter aria-label="Compartir en Twitter" /></a></li>
                            <li><a href={fb_link} target="_blank" rel="nofollow noreferrer"><FaFacebookF aria-label="Compartir en Facebook" /></a></li>
                            <li><a href={ln_link} target="_blank" rel="nofollow noreferrer"><FaLinkedinIn aria-label="Compartir en LinkedIn" /></a></li>
                            <li><a href={wa_link} target="_blank" rel="nofollow noreferrer"><FaWhatsapp aria-label="Compartir en Whatsapp" /></a></li>
                        </ul>  

                        {pageContext.tags &&
                            <div className="post-tags">
                                { pageContext.tags.map((tag, index) => (
                                    <Link key={index} className="btn-tag" to={`/tag/${tag.path ? '' : tag.slug}`}>{tag.name}</Link>
                                ))}
                            </div>              
                        }          
                    </div>




                    <Comentarios id={pageContext.wordpress_id} />
                    <ComentForm id={pageContext.wordpress_id} />

                </div>

                <Sidebar passChildData={setChildData}/>
            </section>
        </Layout>
    )

}
